<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/tamogiefectivo/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">Kishon, S.A. de C.V., SOFOM, E.N.R.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Página delantera</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Sobre nosotros</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">Problema comun</span>
          </router-link>
        </li>
        <!-- <li
          :class="{ 'active-link': $route.path === '/protocol' }"
          class="Li4"
        >
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Acuerdo de privacidad</span>
          </router-link>
        </li> -->
        <li class="dropdown Li4">
          <!-- 添加下拉图标 -->
          <span class="dropdown-toggle routeText4">Acuerdo de privacidad</span>
          <ul class="dropdown-menu">
            <li class="firstLi">
              <a href="https://kish.presupuestokkishapp.com/KishPresupuesto/PrivacyAgreement.html" target="_blank">
                <div class="dropdown-menu-content">
                  <img src="@/page/kishonefectivo2/components/img/app.png" alt="App Store" class="iconImg" />
                  App Store
                </div>
              </a>
            </li>
            <li>
              <a href="https://kish.presupuestokkishapp.com/KishPresupuesto/PrivacyAgreementAndroid.html" target="_blank">
                <div class="dropdown-menu-content">
                  <img src="@/page/kishonefectivo2/components/img/play.png" alt="Google Play" class="iconImg" />
                  Google Play
                </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div style="height: 110px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/jaidee/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky;  /* 将导航栏固定在页面上 */
  top: 0;  /* 位于页面顶部 */
  z-index: 1000;  /* 确保导航栏在其他元素上方 */
  height: 80px;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #FFFFFF;
}


.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 50px;
}

.title {
  width: 560px;
  height: 24px;
  font-size: 20px;
  font-family: Roboto Flex-SemiBold;
  font-weight: 600;
  color: #333333;
  line-height: 23px;
  /* padding-left: 18px; */
  padding-left: 80px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #333333;
  line-height: 29px;
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  /* padding: 8px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 45px;
  /* padding-left: 4px;
  padding-right: 4px; */
  /* border: 1px solid red; */
  align-items: center;
  justify-content: center;
}
.Li1 {
  width: 170px;
}
.Li2 {
  width: 170px;
}
.Li3 {
  width: 170px;
}
.Li4 {
  width: 240px;
}
.lastLi {
  width: 190px;
  margin-right: 28px;
}
/* .active-link {
  background-size: 100% 100%;
} */
.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-size: 18px;
  font-family: Inter-Extra Bold;
  font-weight: 600;
  color: #0AB64A;
}
.active-link .routeText1::after,
.active-link .routeText2::after,
.active-link .routeText3::after,
.active-link .routeText4::after,
.active-link .routeText5::after {
  content: "";
  display: block;
  /* 初始时下划线可见 */
  border-bottom: 4px solid #0AB64A; 
  border-radius: 20px;
  /* 调整下划线的长度 */
  width: 50%; 
  transition: width 0s ease-in-out; /* 添加 transition 效果 */
  /* margin-top: 10px; */
}
.active-link .routeText1::after
 {
  width: 75%;
  margin-left: 15px;
}
.active-link .routeText2::after{
  width: 65%;
  margin-left: 15px;
}
.active-link .routeText3::after {
  margin-left: 15px;
  width: 70%; 
}
.active-link .routeText4::after {
  width: 67%;
  margin-left: 25px;
}

.title{
  /* border: 1px solid red; */
  width: 480px;
}
.secondBox{
  padding: 0 60px 0 50px;
}
.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 10%;
  background-color: #fff;
  padding: 5px 0;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: 0px 6px 12px #ccc;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu li a {
  font-size: 15px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #000000;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* cursor: pointer; */
  height: 100%;
  margin: 0  24px;
}

.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  /* 设置倒三角形的颜色 */
  border-bottom: none;
  /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}

.firstLi {
  border-bottom: 1px solid rgb(212, 212, 212);
}

.iconImg {
  width: 18px;
  height: 18px;
  padding-left: -20px;
  margin: 0 auto;
  margin-right: 10px;
}
.dropdown-menu-content{
  display: flex;
  align-items: center;
}
</style>